import React, { useState } from "react";

import Logo from "../assets/blink-logo.png";
import axios from "axios";
import "../pages/otp.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CaptchaService from "../services/captcha.service";
import { CONFIG } from "../app-config";

export default function OtpVerification({ nextStep, formData, handleOtp, recaptchatoken }) {
  // const [recaptchaToken, setRecaptchaToken] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [loading, setLoading] = useState(false);

  const [otpValues, setOtpValues] = useState(["", "", "", "", ""]);


  const handleOtpChange = (index, value) => {
    const validInput = /^[0-9]*$/;
    if (validInput.test(value) || value === "") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);

      if (value !== "" && index < 4) {
        document.getElementById(`otpValue${index + 2}`).focus();
      }
      if (value === "" && index > 0) {
        document.getElementById(`otpValue${index}`).focus();
      }
    }
  };

  const jsonToFormData = (json) => {
    const formData = new FormData();
    appendFormData(formData, "", json);
    return formData;
  }


  const onOTPPaste = (event) => {
    event.preventDefault();
    const pastedValue = (event.clipboardData || window.clipboardData).getData('text');
    if (pastedValue) {
      const splitValue = pastedValue.slice(0, 5).split('');
      const otp = Array(5).fill('').map((v, i) => i <= splitValue.length ? splitValue[i] : '');
      setOtpValues([...otp]);
      document.getElementById(`otpValue${Math.min(splitValue.length + 1, otp.length)}`).focus();
    }
  }

  const appendFormData = (formData, key, value) => {
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        appendFormData(formData, key + "[" + i + "]", value[i]);
      }
    } else if (typeof value === "object" && value !== null) {
      for (const k in value) {
        if (value.hasOwnProperty(k)) {
          const newKey = key === "" ? k : key + "[" + k + "]";
          appendFormData(formData, newKey, value[k]);
        }
      }
    } else {
      formData.append(key, value);
    }
  }

  const reSendOtp = async () => {
    const captchaService = new CaptchaService();
    const token = await captchaService.generateCaptchaToken();

    if (!token) {
      toast.error("Invalid Captcha");
      return;
    }

    let data = {
      username: `${formData.firstname || ''} ${formData.lastname || ''}`,
      recaptchatoken: token
    }


    if (CONFIG.IS_PHONE_OTP_ENABLED) {
      data.mobile = formData.phone;
    } else {
      data.email = formData.email;
    }


    try {
      const urlData = {
        service: "authService",
        method: "checkClientSendOtp",
        data: data
      };

      const localFormData = jsonToFormData(urlData);
      localFormData.append("request", JSON.stringify(urlData));
      //appendFormData(formData,"",urlData)

      const response = await axios.post(
        `${CONFIG.API_PATH}`,
        localFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        console.log("OTP sent successfully");
        toast.success(`OTP has been successfully sent to your ${CONFIG.IS_PHONE_OTP_ENABLED ? 'mobile number' : 'Email'}`);
      } else {
        toast.error(
          `OTP sending failed: ${response.data.error.msg || "unknown error"}`
        );
      }
    } catch (error) {
      toast.error("Error sending OTP", error);
    }
  }


  const reSendOtpTimer = async () => {
    setResendDisabled(true);
    setCountdown(30);

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          setResendDisabled(false);
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };
  const verifyOtp = (e) => {
    e.preventDefault();
    const otp = Object.values(otpValues).join("");
    setLoading(true);
    const urlData = {
      service: "authService",
      method: "verifyClientOtp",
      data: {
        otpKeyName: CONFIG.IS_PHONE_OTP_ENABLED ? formData.phone : formData.email,
        otp: otp,
        //recaptchaToken,
      },
    };

    axios
      .post(CONFIG.API_PATH, `request=${JSON.stringify(urlData)}`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.code === "100") {
          handleOtp(otp);
          setLoading(false);
          nextStep();

          toast.success("Succesfully Verified OTP");
        } else {
          toast.error("Invalid OTP");
        }
      })
      .catch((error) => {
        toast.error("Error verifying OTP:", error);

      })
      .finally(() => {
        setLoading(false);
      })
  };

  return (

    <div className="card client-otp-container mt-3  p-0">
      <div className="row">
        <div className="col-12 p-0">
          <div className="client-otp-heading">

            <h4 className=" mb-3">
              Enter OTP to verify account
            </h4>
            <span>An OTP has been sent to {CONFIG.IS_PHONE_OTP_ENABLED ? `*******${formData?.phone?.toString()?.slice(-3)}` : formData.email.replace(/(?<=.{2}).(?=[^@]*@)/g, '*')}</span>
          </div>
        </div>
      </div>
      <form onSubmit={verifyOtp}>
        <div className="row">
          <div className="col-12 p-0">
            <div className="otp mt-2 mb-2" id="inputs">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  name={`otpValue${index + 1}`}
                  type="text"
                  id={`otpValue${index + 1}`}
                  maxLength="1"
                  autoComplete="off"
                  value={value}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onPaste={onOTPPaste}
                />
              ))}

              {/*<input name="otpvalue6" type="text" id="sixth" maxLength="1" autoComplete="off"/> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-2 mb-2">
            <div className="resend-otp ">
              {resendDisabled
                ? (
                  <a href="#" className="resend-btn" style={{ textDecoration: "none", cursor: "not-allowed" }} disabled={setResendDisabled}>
                    Resend OTP ({countdown}s)
                  </a>
                ) : (
                  <a href="#" className="resend-btn" style={{ textDecoration: "none", cursor: "pointer" }} onClick={() => [reSendOtpTimer(), reSendOtp()]}>
                    Resend-OTP
                  </a>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-0 mb-4">
            <div className="buttons mt-3  ">
              <button className="submit" type="submit" disabled={loading}>
                {loading ? <div disabled>
                  Verify Otp
                  <span class="spinner-border spinner-border-sm " style={{ marginLeft: '5%', color: '#fffff' }} role="status" aria-hidden="true"></span>
                </div> : "Verify Otp"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
