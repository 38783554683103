import React, { useEffect } from 'react'
import { Container, Button, Link } from 'react-floating-action-button'

const Support = () => {

    useEffect(() => {
        document.querySelectorAll(".support-container a").forEach((e, index) => {
            e.setAttribute("target", "_blank");
            e.children[0].id = e.classList.value.trim().split(/\s/)[1] || `support_${index}`;

        });
    }, []);

    return (
        <Container style={{
            bottom: "10vh",
            position: "fixed",
            margin: "1em",
        }}
            className="support-container"
        >


            <Link href="mailto:support@blinkexam.com"
                icon="fa fa-envelope-o fa-2x fa-light"
                styles={{ backgroundColor: "#fff", color: "#ff4343", height: "50px", width: "50px" }}
                className="support-email"
                target="_blank" />
            <Link href="https://api.whatsapp.com/send/?phone=9582874969&text&type=phone_number&app_absent=1"
                icon="fa fa-whatsapp fa-2x"
                styles={{ backgroundColor: "#fff", color: "#25d366", height: "50px", width: "50px" }}
                target="_blank"
                className="support-whatsapp"
            />
            <Button
                icon="fa fa-headphones fa-2x"
                styles={{ backgroundColor: "#fff", color: "#283E9C" }}
            >
            </Button>
        </Container >
    )
}

export default Support;