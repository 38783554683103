export const CONFIG = {
    GOOGLE_CAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY,
    API_PATH: process.env.REACT_APP_API_PATH,
    DOMAIN_NAME: process.env.REACT_APP_DOMAIN_NAME,
    PREFIX_NAME: process.env.REACT_APP_PREFIX_NAME,
    GA_ID: process.env.REACT_APP_GA_ID,
    IS_PHONE_OTP_ENABLED: !!process.env.REACT_APP_IS_PHONE_OTP_ENABLED,
    ENABLE_V2_UI: !!process.env.REACT_APP_ENABLE_V2_UI,
    ONLY_INDIA:!!process.env.REACT_APP_ONLY_INDIA,
    API_PATH_V2:process.env.REACT_APP_V2_API_PATH,
    IS_MOBILE_OTP:process.env.REACT_APP_IS_MOBILE_OTP,






} 