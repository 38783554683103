import React, { useCallback, useEffect, useState } from "react";
import Logo from "./assets/latesLogo.png";
import SignUp from "./pages/SignUp";
import OtpVerification from "./pages/OtpVerification";
import WebUrl from "./pages/WebUrl";
import OtpVerificationV2 from "./pages/v2/Otp/OtpVerificationV2";
import WebUrlV2 from "./pages/v2/WebUrl/WebUrlV2";
import "./pages/Form.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaptchaService from "./services/captcha.service";
import './App.css';
import Support from "./components/Support";
import useGoogleAnalytics from "./custom-hooks/ga-hook";
import { GA_CATEGORY } from "./constant";
import { CONFIG } from "./app-config";
import SignUpV2 from "./pages/v2/SignUp/SignUpV2";
import { useLocation } from "react-router-dom";
import iplocation from "iplocation";



export default function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [childOtp, setChildtOtp] = useState({});
  const [country, setCountry] = useState("");
  const [ip, setIP] = useState("");



  const executeRecaptcha = useCallback(async () => {
    const captchaService = new CaptchaService();
    await captchaService.generateCaptchaToken();
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParamValue = searchParams.get('utm') || '';


  useGoogleAnalytics(GA_CATEGORY.USER_INTERACTIONS);

  useEffect(() => {
    executeRecaptcha();
  }, [executeRecaptcha]);

  const handleFormData = (data) => {
    setFormData(data);
  };

  const handleOtp = (otp) => {
    setChildtOtp(otp);
    setFormData((prevFormData) => ({
      ...prevFormData,
      otp: otp,
    }));
  };
  const getData = async () => {
    try {
      // Fetch the user's IP address
      const res = await fetch("https://api.ipify.org/?format=json");
      const data = await res.json();
      const ip = data.ip;
      setIP(ip);

      // Fetch the geographical information about the IP address
      const location = await iplocation(ip);
      console.log('IP Location:', location); // Debug log

      setCountry(location.country.code.toLowerCase());
    } catch (error) {
      console.error("Error fetching IP or country information:", error);
    }
  };


  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };


  const UI_VERSION_V1 = {
    'SignUp': SignUp,
    'OtpVerification': OtpVerification,
    'WebUrl': WebUrl,

  }
  const UI_VERSION_V2 = {
    'SignUp': SignUpV2,
    'OtpVerification': OtpVerificationV2,
    'WebUrl': WebUrlV2,
  };

  const STEP_TO_COMPONENT_MAPPER = {
    1: 'SignUp',
    2: 'OtpVerification',
    3: 'WebUrl'
  }


  const UI_VERSION = CONFIG.ENABLE_V2_UI ? UI_VERSION_V2 : UI_VERSION_V1;
  // console.log(CONFIG.ENABLE_V2_UI);
  // console.log(CONFIG.ENABLE_V2_UI ? "V2" : "V1")
  const Component = UI_VERSION[STEP_TO_COMPONENT_MAPPER[currentStep]];

  return (
    <div className="app-login-new-container">
      <ToastContainer />
      <div className="row" style={{ height: '100vh' }}>
        <div className="card col-12  card-data-container " >
          {/* <div className="card col-12 col-md-10 col-lg-8 col-xl-10  card-data-container" > */}
          <div className=" d-flex justify-content-center ">
            {/* <div className="" style={{ margin: 'auto' }}>
              <img src={Logo} className="img-fluid" alt="BlinkExam" />
            </div> */}
          </div>
          {currentStep === 1 && (
            <Component
              nextStep={nextStep}
              formData={formData}
              handleFormData={handleFormData}
              country={country}
              getData={getData}
              
            />
          )}
          {currentStep === 2 && (
            <Component
            nextStep={nextStep}
            formData={formData}
            setFormData={setFormData}
            handleOtp={handleOtp}
            country={country}
            getData={getData}
            />
          )}
          {currentStep === 3 && (
            <Component
            nextStep={nextStep}
            formData={formData}
            childOtp={childOtp}
            utm={queryParamValue}
            />
          )}
        </div>

      </div>
      <Support />
    </div >
  );
}
