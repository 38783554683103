import React, { useEffect, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Axios from "axios";
import "../SignUp/SignUpV2.css";
import { signupValidationV1, signupValidationV2 } from "../../v2/SignUp/signupValidationV2";
import { jsonToFormData } from "../../../utils";
import CaptchaService from "../../../services/captcha.service";
import { CONFIG } from "../../../app-config";
import "react-phone-input-2/lib/style.css";
import PhoneInput from 'react-phone-input-2';
import { OTP_SERVICE, WHATSAPP_API_ENDPOINT } from "../../../constant";
import { config } from "dotenv";



const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirm_password: "",
  phone: "",
  industry: "",
};
export default function SignUpV2({ nextStep, handleFormData,country,getData}) {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [
    selectedValue,
    setSelectedValue,
  ] = useState(OTP_SERVICE.MASSAGE);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
    
  };

  useEffect(() => {
    {country==="in"?setSelectedValue(OTP_SERVICE.MASSAGE):setSelectedValue(OTP_SERVICE.WHATSAPP)}
    getData();
    document.querySelector("#lead").innerHTML = `<iframe id="leadIframe" sandbox="allow-same-origin allow-scripts allow-forms"  style="display:none" src="lead.html"></iframe>`;
  }, [country])


  const handleError = (error) => {
    toast.error("Error sending OTP", error);
    setLoading(false);
  };
  const getOtpMethod = () => {
    if (!CONFIG.IS_PHONE_OTP_ENABLED) {
      return 'email';
    }
    return selectedValue === OTP_SERVICE.MASSAGE
      ? OTP_SERVICE.MASSAGE
      : OTP_SERVICE.WHATSAPP;
  };

  const handleOtpResponse = (response, method) => {
    console.log(`Handling OTP response for ${method}:`, response);

    const otpMethod = getOtpMethod();

    if (method === 'Text Message' || method === 'WhatsApp') {
      if (response.data.success || response.data.message) {
        toast.success(`OTP has been successfully sent to your ${otpMethod}`);
        setLoading(false);
        nextStep();
      } else {
        const errorMsg = response.data.error?.msg || response.data.message || "unknown error";
        if (errorMsg.includes("mobile has already been taken")) {
          toast.error("The mobile number is already registered. Please use a different number.");
        } else {
          toast.error(`OTP sending failed: ${errorMsg}`);
        }
        setLoading(false);
      }
    } else {
      toast.error("Unknown OTP method");
      setLoading(false);
    }
  };



  const sendOtp = async (formValues) => {
    const captchaService = new CaptchaService();
    const token = await captchaService.generateCaptchaToken();

    if (!token) {
      toast.error("Invalid Captcha");
      return;
    }

    let data = {
      username: `${formValues.firstname || ''} ${formValues.lastname || ''}`,
      recaptchatoken: token
    };
    let whatsappData = {
      recaptcha_token: token
    };

    if (CONFIG.IS_PHONE_OTP_ENABLED) {
      let phone = formValues.phone.startsWith('91') ? formValues.phone.slice(2) : formValues.phone;
      if (selectedValue === OTP_SERVICE.MASSAGE) {
        data.mobile = phone;
        sendTextMessageOtp(data);
      } else if (selectedValue === OTP_SERVICE.WHATSAPP) {
        whatsappData.mobile = phone;
        sendWhatsappOtp(whatsappData);
      }
    } else {
      data.email = formValues.email;
      sendTextMessageOtp(data);
    }
  };


  const sendTextMessageOtp = async (data) => {
    try {
      console.log('Sending OTP via text message with data:', data);
      const urlData = {
        service: "authService",
        method: "checkClientSendOtp",
        data: data,
      };
      const formData = jsonToFormData(urlData);
      formData.append("request", JSON.stringify(urlData));
      const response = await Axios.post(`${CONFIG.API_PATH}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log('Text message OTP response:', response);
      handleOtpResponse(response, 'Text Message');
    } catch (error) {
      console.error('Error sending text message OTP:', error);
      handleError(error);
    }
  };

  const sendWhatsappOtp = async (whatsappData) => {
    try {
      console.log('Sending OTP via WhatsApp with data:', whatsappData);
      const response = await Axios.post(
        `${CONFIG.API_PATH_V2}${WHATSAPP_API_ENDPOINT.WHATSAPP_ENDPOINT}`,
        whatsappData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log('WhatsApp OTP response:', response);
      handleOtpResponse(response, 'WhatsApp');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const errorMsg = error.response.data.message || "unknown error";
        if (errorMsg.includes("mobile has already been taken")) {
          toast.error("OTP sending failed: mobile number already registered.");
          setLoading(false)
        } else {
          toast.error(`OTP sending failed: ${errorMsg}`);
        }
      } else {
        console.error('Error sending WhatsApp OTP:', error);
        handleError(error);
      }
    }
  };

  const { values, handleChange, handleSubmit, isValid, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CONFIG.IS_PHONE_OTP_ENABLED ? signupValidationV1 : signupValidationV2, // Todo: This can be handled better. signupValidationV2  update when whatapp api intigrate
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (values) => {
        setLoading(true);
        if (isValid && nextStep) {
          await sendOtp(values);
          handleFormData(values);
        }
      },
    });

  const submitLead = () => {
    const iframeWindow = document.getElementById('leadIframe').contentWindow;
    // Send data and trigger the form submission in the iframe
    const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values?.email || '');
    const isPhoneValid = (parseInt(values.phone) && values.phone.length === 15 && /^\+?[1-9]\d{0,2}[-.\s]?\d{10,15}$/.test(values?.phone || ''));
    const submittable = (isEmailValid || isPhoneValid);


    const name = `${values.firstname} ${values?.lastname}`.trim();

    if (submittable) {
      console.log("Lead Sent");
      iframeWindow.postMessage({
        action: 'submitForm',
        data: {
          first_name: name && name.length ? name : "Anonymous Lead",
          phone_mobile: values?.phone,
          email: values.email || "anonymous@blinkexam.com"
        }
      }, '*');

      setTimeout(() => {
        document.querySelector("#lead").innerHTML = `<iframe id="leadIframe" sandbox="allow-same-origin allow-scripts allow-forms"  style="display:none" src="lead.html"></iframe>`;
      }, 2500);
    }
  }
  const handleFocus = (e) => {
    const input = e.target;
    const container = input.closest('.form-group');
    const label = container.querySelector('label');

    if (label && label.classList) {
      label.classList.add('floating-label');
    }
  };

  const handleBlur = (e) => {
    const input = e.target;
    const container = input.closest('.form-group');
    const label = container.querySelector('label');

    if (label && label.classList && !input.value.trim()) {
      label.classList.remove('floating-label');
    }
  };

  const handlePhoneInputKeyDown = (event) => {
    const { key, target } = event;
    const { value, selectionStart, selectionEnd } = target;

    const countryCodeMatch = value.match(/^\+\d+/);
    const countryCode = countryCodeMatch ? countryCodeMatch[0] : '';
  
    if (key === 'Backspace' ||key==='Delete') {
      if (selectionStart === 0 && selectionEnd === value.length) {
        target.value = countryCode;
        target.setSelectionRange(countryCode.length, countryCode.length);
        event.preventDefault();
      } else if (value === countryCode || value.length <= countryCode.length) {
        event.preventDefault();
      }
    }

    if (key.length === 1) {
      if (selectionStart === 0 && selectionEnd === value.length) {
        target.value = countryCode;
        target.setSelectionRange(countryCode.length, countryCode.length);
        event.preventDefault();
      } else if (selectionStart < countryCode.length) {
        event.preventDefault();
      }
    }
  };



  //console.log(ip)

  //console.log('recaptchatoken', recaptchatoken);
  return (
    <div className="client-signup-container ">
      <div className="client-container-heading">
        <h3>Sign up now to start your free trail. </h3>
      </div>
      <div className="client-container-text  mt-3">
        <p>Complete the form to start your free trial. Our team will be in touch to help you make the most of your trial. </p>
      </div>
      <form className="" onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="firstname" className={values.firstname ? 'floating-label' : ''} style={{ padding: 0 }}>First Name*</label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                className="form-control first"
                placeholder=" "
                autoComplete="off"
                maxLength={70}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                value={values.firstname}
              />
            </div>
            {errors.firstname && <small>{errors.firstname}</small>}
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="lastname" className={values.lastname ? 'floating-label' : ''} style={{ padding: 0 }}>Last Name*</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                className="form-control"
                placeholder=" "
                autoComplete="off"
                maxLength={70}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                value={values.lastname}
              />
            </div>
            {errors.lastname && <small>{errors.lastname}</small>}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="email" className={values.email ? 'floating-label' : ''} style={{ padding: 0 }}>Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control email"
                placeholder=" "
                autoComplete="off"
                onBlur={(e) => {
                  submitLead();
                  handleBlur(e);
                }}
                onFocus={handleFocus}
                onChange={handleChange}
                value={values.email?.toLowerCase() || ""}
              />
            </div>
            {errors.email && <small>{errors.email}</small>}
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="phone" className={values.phone ? 'floating-label' : ''} style={{ marginLeft: 35, padding: 0, backgroundColor: 'white ', zIndex: 999 }}>Phone{CONFIG.IS_PHONE_OTP_ENABLED ? '*' : ' (optional)'}</label>

              <PhoneInput
                country={country ? country : 'in'}
                id="phone"
                autoComplete="off"
                value={values.phone}
                onChange={(phone) => handleChange({ target: { name: 'phone', value: phone } })}
                onKeyDown={handlePhoneInputKeyDown}
                // onlyCountries={CONFIG.ONLY_INDIA ? ['in'] : undefined}

                inputProps={{
                  maxLength: 15,
                  name: 'phone',
                  required: true,
                  autoFocus: false,
                  onBlur: handleBlur,
                  onFocus: handleFocus,
                  placeholder: " ",

                }}
                inputClass="phone-input"

                dropdownStyle={{ width: 200 }}

              />

              {errors.phone && <small className="text-danger">{errors.phone}</small>}
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6 col-lg-6">
            <div className="form-group " id="icon-id">
              <div className="input-icon-container">
                <label htmlFor="password" className={values.password ? 'floating-label' : ''} style={{ padding: 0 }}>Password*</label>
                <input
                  type={isVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  className="form-control password"
                  placeholder=" "
                  autoComplete="off"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onChange={handleChange}
                  value={values.password}
                />
                <span
                  className="eye-icon"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  {isVisible ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </span>
              </div>
            </div>
            {errors.password && <small>{errors.password}</small>}
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group client-pass ">
              <div className="input-icon-container">
                <label htmlFor="confirm_password" className={values.confirm_password ? 'floating-label' : ''} style={{ padding: 0 }}>Confirm Password*</label>
                <input
                  type={isVisibleConfirmPassword ? "text" : "password"}
                  id="confirm_password"
                  name="confirm_password"
                  className="form-control"
                  placeholder=" "
                  autoComplete="off"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onChange={handleChange}
                  value={values.confirm_password}
                />
                <span
                  className="eye-icon"
                  onClick={() =>
                    setIsVisibleConfirmPassword(!isVisibleConfirmPassword)
                  }
                >
                  {isVisibleConfirmPassword ? (
                    <IoEyeOutline />
                  ) : (
                    <IoEyeOffOutline />
                  )}
                </span>
              </div>
            </div>
            {errors.confirm_password && <small>{errors.confirm_password}</small>}
          </div>
        </div>
        <div className="row ">
          {/* <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <input
                type="text"
                name="phone"
                className="form-control"
                placeholder="Phone"
                maxLength={10}
                autoComplete="off"
                onBlur={(e) => {
                  submitLead();
                  handleBlur(e);
                }}
                onChange={handleChange}
                value={values.phone}
              />
            </div>
 
            {errors.phone && <small>{errors.phone}</small>}
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6 mb-3">
            <div className="form-group">
              <select
                name="industry"
                className="form-control form-select select-menu "
                id="exampleFormControlSelect1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.industry}
              >
                <option value="">
                  Select Industry Type
                </option>
                <option value="Education Sector">Education Sector</option>
                <option value="Corporate Sector">Corporate Sector</option>
                <option value="Government and Public Sector">
                  Government and Public Sector
                </option>
                <option value="Information Technology (IT) and Software">
                  Information Technology (IT) and Software
                </option>
                <option value="Healthcare and Medical Sector">
                  Healthcare and Medical Sector
                </option>
                <option value="Finance and Banking">Finance and Banking</option>
              </select>
              {errors.industry && (
                <small>{errors.industry}</small>
              )}


            </div>
          </div>
        </div>
        {CONFIG.IS_PHONE_OTP_ENABLED ?
          <div className="otp-Choice-container mb-3">
            <h1 className="heading">
              OTP Received via Text Message or WhatsApp
            </h1>
            <div
              className="container p-0"
            >
              <div
                className="radioGroup"
              >
                {country==="in" &&(
                <div
                  className="radioButton"
                >

                  <input
                    type="radio"

                    value={OTP_SERVICE.MASSAGE}
                    checked={
                      selectedValue ===
                      OTP_SERVICE.MASSAGE
                    }
                    onChange={() => handleRadioChange(OTP_SERVICE.MASSAGE)}
                  />
                  <p
                    htmlFor={OTP_SERVICE.MASSAGE}
                    className="radioLabel"
                  >
                    Text massage
                  </p>
                </div>
                  )}
                <div
                  className="radioButton"
                >
                  <input
                    type="radio"

                    value={OTP_SERVICE.WHATSAPP}
                    checked={
                      selectedValue ===
                      OTP_SERVICE.WHATSAPP
                    }
                    onChange={() => handleRadioChange(OTP_SERVICE.WHATSAPP)}
                  />
                  <p
                    htmlFor={OTP_SERVICE.WHATSAPP}
                    className="radioLabel"
                  >
                    WhatsApp
                  </p>
                </div>
              </div>
            </div>
          </div> : null}
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="terms-check" style={{}} >
              <small className="inp-check"><input name="terms_and_conditions" style={{ "width": "15px", "margin": "-1px", marginTop: '5px' }} type="checkbox" checked onChange={(e) => setIsChecked(e.target.checked)} /></small>

              <small style={{ color: 'black' }} className="mt-0 text-field">
                By clicking on get started, You agree to the  &nbsp;

                <span>
                  <a
                    href="https://www.blinkexam.com/terms-of-services/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </span>
                &nbsp;
                and
                &nbsp;
                <span>
                  <a
                    href="https://www.blinkexam.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              </small>
            </div>
          </div>

        </div>
        <div className="client-container-bottom-text  mt-3">
          <p>Your free trial may be provisioned on or migrated to Hyperforce. BlinkExam public cloud infrastructure</p>
        </div>
        <div className="client-container-bottom-text ">
          <p>By registering, you confirm that you agree to the storing and processing of your personal data by BlinkExam as described in the Privacy Statement.</p>
        </div>

        <div className="row mb-2">
          <div className="col-md-12 col-lg-12  " style={{ textAlign: 'center' }}>
            <button
              className="btn Signup col-12" style={{}}
              type="submit"
              disabled={loading || !isChecked} I
            >
              START MY FREE TRIAL
              {loading ? <span class="spinner-border spinner-border-sm " style={{ marginLeft: '5%', color: '#fffff' }} role="status" aria-hidden="true"></span>
                : null}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

