import ReactGA from 'react-ga4';
import { CONFIG } from '../app-config';
import { GA_CATEGORY, GA_ACTIONS } from '../constant';
import { useEffect } from 'react';


const useGoogleAnalytics = (gaCategory) => {
    const handleClick = (event) => {
        if (['BUTTON', 'A', 'I'].includes(event.target.tagName)) {
            const label = event.target.innerText  || event.target.getAttribute('id') || event.target.getAttribute('href')  || 'Unnamed Entry';
            trackEvent(gaCategory, GA_ACTIONS.CLICKED, label);
        }
    };


    const handleInputFocus = (event) => {
        const focusedTag = event.target.tagName;
        if (focusedTag === 'INPUT' || focusedTag === 'TEXTAREA' || focusedTag === 'SELECT') {
            const inputLabel = event.target.getAttribute('name') || event.target.getAttribute('id') || 'Unnamed input';
            trackEvent(gaCategory, GA_ACTIONS.INPUT_FOCUSED, inputLabel);
        }
    };

    const handleInputBlur = (event) => {
        const bluredTag = event.target.tagName;
        if (bluredTag === 'INPUT' || bluredTag === 'TEXTAREA' || bluredTag === 'SELECT') {
            const inputLabel = event.target.getAttribute('name') || event.target.getAttribute('id') || 'Unnamed input';
            trackEvent(gaCategory, GA_ACTIONS.INPUT_BLURED, inputLabel);
        }
    };

    useEffect(() => {
        document.addEventListener('focus', handleInputFocus, true);
        document.addEventListener('blur', handleInputBlur, true);
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
            document.removeEventListener('focus', handleInputFocus);
            document.removeEventListener('blur', handleInputBlur);
        };
    }, []);

    const trackEvent = (category, action, label) => {
        if (!CONFIG.GA_ID) return;


        ReactGA.event({
            category,
            action,
            label,
        });
    };

    return {
        trackEvent,
    };
};


export default useGoogleAnalytics;
