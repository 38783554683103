import React, { useEffect, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Axios from "axios";
import "../pages/Signup.css";
import { signupValidationV1, signupValidationV2 } from "./signupValidation";
import { jsonToFormData } from "../utils";
import CaptchaService from "../services/captcha.service";
import { CONFIG } from "../app-config";


const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirm_password: "",
  phone: "",
  industry: "",
};


export default function SignUp({ nextStep, handleFormData }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    document.querySelector("#lead").innerHTML = `<iframe id="leadIframe" sandbox="allow-same-origin allow-scripts allow-forms"  style="display:none" src="lead.html"></iframe>`;
  }, [])

  const sendOtp = async (formValues) => {
    const captchaService = new CaptchaService();
    const token = await captchaService.generateCaptchaToken();

    if (!token) {
      toast.error("Invalid Captcha");
      return;
    }

    let data = {
      username: `${formValues.firstname || ''} ${formValues.lastname || ''}`,
      recaptchatoken: token
    }


    if (CONFIG.IS_PHONE_OTP_ENABLED) {
      data.mobile = formValues.phone;
    } else {
      data.email = formValues.email;
    }


    try {
      const urlData = {
        service: "authService",
        method: "checkClientSendOtp",
        data: data
      };

      const formData = jsonToFormData(urlData);
      formData.append("request", JSON.stringify(urlData));
      const response = await Axios.post(
        `${CONFIG.API_PATH}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        console.log("OTP sent successfully");
        toast.success(`OTP has been successfully sent to your ${CONFIG.IS_PHONE_OTP_ENABLED ? 'mobile number' : 'Email'}`);
        setLoading(true);
        // setFormSubmitted(true)
        nextStep();
      } else {
        toast.error(
          `OTP sending failed: ${response.data.error.msg || "unknown error"}`
        );
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error sending OTP", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const { values, handleChange, handleSubmit, isValid, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CONFIG.IS_PHONE_OTP_ENABLED ? signupValidationV1 : signupValidationV2, // Todo: This can be handled better
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: async (values) => {
        setLoading(true);
        if (isValid && nextStep) {
          await sendOtp(values);
          handleFormData(values);
        }
      },
    });

  const submitLead = () => {
    const iframeWindow = document.getElementById('leadIframe').contentWindow;
    // Send data and trigger the form submission in the iframe
    const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values?.email || '');


    const isPhoneValid = (parseInt(values.phone) && values.phone.length === 15 && /^[0-9]{10}$/.test(values?.phone || ''));





    const submittable = (isEmailValid || isPhoneValid);


    const name = `${values.firstname} ${values?.lastname}`.trim();

    if (submittable) {
      console.log("Lead Sent");
      iframeWindow.postMessage({
        action: 'submitForm',
        data: {
          first_name: name && name.length ? name : "Anonymous Lead",
          phone_mobile: values?.phone,
          email: values.email || "anonymous@blinkexam.com"
        }
      }, '*');

      setTimeout(() => {
        document.querySelector("#lead").innerHTML = `<iframe id="leadIframe" sandbox="allow-same-origin allow-scripts allow-forms"  style="display:none" src="lead.html"></iframe>`;
      }, 2500);
    }
  }
  const handleFocus = (e) => {
    const input = e.target;
    const container = input.closest('.form-group');
    const label = container.querySelector('label');

    if (label && label.classList) {
      label.classList.add('floating-label');
    }
  };

  const handleBlur = (e) => {
    const input = e.target;
    const container = input.closest('.form-group');
    const label = container.querySelector('label');

    if (label && label.classList && !input.value.trim()) {
      label.classList.remove('floating-label');
    }
  };



  //console.log('recaptchatoken', recaptchatoken);

  return (
    <div className="client-signup-container">
      <div className="d-flex justify-content-center">
        <div className="client-container-heading">
          <h3>Onboard To Blink Journey</h3>
        </div>
      </div>
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="row mb-4">
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="firstname" className={values.firstname ? 'floating-label' : ''} style={{ padding: 0 }}>First Name*</label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                className="form-control first"
                placeholder=" "
                autoComplete="off"
                maxLength={70}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                value={values.firstname}
              />
            </div>
            {errors.firstname && <small>{errors.firstname}</small>}
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="lastname" className={values.lastname ? 'floating-label' : ''} style={{ padding: 0 }}>Last Name*</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                className="form-control"
                placeholder=" "
                autoComplete="off"
                maxLength={70}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
                value={values.lastname}
              />
            </div>
            {errors.lastname && <small>{errors.lastname}</small>}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="email" className={values.email ? 'floating-label' : ''} style={{ padding: 0 }}>Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control email"
                placeholder=" "
                autoComplete="off"
                onBlur={(e) => {
                  submitLead();
                  handleBlur(e);
                }}
                onFocus={handleFocus}
                onChange={handleChange}
                value={values.email?.toLowerCase() || ""}
              />
            </div>
            {errors.email && <small>{errors.email}</small>}
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group">
              <label htmlFor="phone" className={values.phone ? 'floating-label' : ''} style={{ padding: 0 }}>Phone{CONFIG.IS_PHONE_OTP_ENABLED ? '*' : ' (optional)'}</label>

              <input
                type="number"
                id="phone"
                name="phone"
                className="form-control"
                placeholder=" "
                maxLength={10}
                autoComplete="off"
                onBlur={(e) => {
                  submitLead();
                  handleBlur(e);
                }}
                onFocus={handleFocus}
                onChange={handleChange}
                value={values.phone}
              />
            </div>
            {errors.phone && <small>{errors.phone}</small>}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-6 col-lg-6">
            <div className="form-group " id="icon-id">
              <div className="input-icon-container">
                <label htmlFor="password" className={values.password ? 'floating-label' : ''} style={{ padding: 0 }}>Password*</label>
                <input
                  type={isVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  className="form-control password"
                  placeholder=" "
                  autoComplete="off"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onChange={handleChange}
                  value={values.password}
                />
                <span
                  className="eye-icon"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  {isVisible ? <IoEyeOutline /> : <IoEyeOffOutline />}
                </span>
              </div>
            </div>
            {errors.password && <small>{errors.password}</small>}
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="form-group client-pass ">
              <div className="input-icon-container">
                <label htmlFor="confirm_password" className={values.confirm_password ? 'floating-label' : ''} style={{ padding: 0 }}>Confirm Password*</label>
                <input
                  type={isVisibleConfirmPassword ? "text" : "password"}
                  id="confirm_password"
                  name="confirm_password"
                  className="form-control"
                  placeholder=" "
                  autoComplete="off"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onChange={handleChange}
                  value={values.confirm_password}
                />
                <span
                  className="eye-icon"
                  onClick={() =>
                    setIsVisibleConfirmPassword(!isVisibleConfirmPassword)
                  }
                >
                  {isVisibleConfirmPassword ? (
                    <IoEyeOutline />
                  ) : (
                    <IoEyeOffOutline />
                  )}
                </span>
              </div>
            </div>
            {errors.confirm_password && <small>{errors.confirm_password}</small>}
          </div>
        </div>
        <div className="row ">
          {/* <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <input
                type="text"
                name="phone"
                className="form-control"
                placeholder="Phone"
                maxLength={10}
                autoComplete="off"
                onBlur={(e) => {
                  submitLead();
                  handleBlur(e);
                }}
                onChange={handleChange}
                value={values.phone}
              />
            </div>

            {errors.phone && <small>{errors.phone}</small>}
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6 mb-3">
            <div className="form-group">
              <select
                name="industry"
                className="form-control form-select select-menu "
                id="exampleFormControlSelect1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.industry}
              >
                <option value="">
                  Select Industry Type
                </option>
                <option value="Education Sector">Education Sector</option>
                <option value="Corporate Sector">Corporate Sector</option>
                <option value="Government and Public Sector">
                  Government and Public Sector
                </option>
                <option value="Information Technology (IT) and Software">
                  Information Technology (IT) and Software
                </option>
                <option value="Healthcare and Medical Sector">
                  Healthcare and Medical Sector
                </option>
                <option value="Finance and Banking">Finance and Banking</option>
              </select>
              {errors.industry && (
                <small>{errors.industry}</small>
              )}


            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="terms-check" style={{}} >
              <small className="inp-check"><input name="terms_and_conditions" style={{ "width": "15px", "margin": "-1px", marginTop: '5px' }} type="checkbox" checked onChange={(e) => setIsChecked(e.target.checked)} /></small>

              <small style={{ fontSize: "16px", color: 'black' }} className="mt-0">
                By clicking on get started, You agree to the  &nbsp;

                <span>
                  <a
                    href="https://www.blinkexam.com/terms-of-services/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </span>
                &nbsp;
                and
                &nbsp;
                <span>
                  <a
                    href="https://www.blinkexam.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              </small>
            </div>
          </div>

        </div>

        <div className="row mb-2">
          <div className="col-md-12 col-lg-12  mt-4" style={{ textAlign: 'center' }}>
            <button
              className="btn Signup col-12" style={{ "backgroundColor": '#283E9C', color: 'white', marginBottom: '25px', border: 'none', width: '55%' }}
              type="submit"
              disabled={loading || !isChecked} I
            >
              Sign Up
              {loading ? <span class="spinner-border spinner-border-sm " style={{ marginLeft: '5%', color: '#fffff' }} role="status" aria-hidden="true"></span>
                : null}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}