import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdOutlineCopyAll } from "react-icons/md";
import "../pages/weburl.css";
import axios from "axios";
import { toast } from "react-toastify";
import ProfileCreate from "./ProfileCreate";
import CaptchaService from "../services/captcha.service";
import { CONFIG } from "../app-config";

export default function WebUrl({ nextStep, formData, recaptchatoken }) {
  const [url, setUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const [manage, setManage] = useState(false)
  const [loading, setLoading] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);


  const handleUrlChange = (value) => {
    setUrl(value.replaceAll(/[^a-z]/g, ''));
    setCopied(false);
    setManage(false);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
    if (manage) {
      setTimeout(() => {
        setManage(false);
      }, 3000);
    }
  }, [copied, manage])


  const register = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const captchaService = new CaptchaService();
      const token = await captchaService.generateCaptchaToken();

      if (!token) {
        toast.error("Invalid Captcha");
        return;
      }

      const urlData = {
        service: "authService",
        method: "addClient",
        data: {
          mobile: formData.phone,
          firstname: formData.firstname.trim(),
          lastname: formData.lastname.trim(),
          email: formData.email.trim(),
          psw: formData.password.trim(),
          industrytype: formData.industry,
          url: url,
          otp: formData.otp,
          //demodata: formData.checkboxG1 ? "1" : "0",
          recaptchatoken: token,
        },
      };

      axios
        .post(CONFIG.API_PATH, `request=${JSON.stringify(urlData)}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          const dataArr = response.data;
          if (dataArr.code === "100") {
            setLoading(true);
            setLoadingSuccess(true);
          } else {
            // Handle error
            setLoading(false);
            toast.error(
              `Error while creating URL: ${response?.data?.error?.msg || "Something went wrong!!"
              }`
            );
          }
        });
    } catch (error) {
      toast.error("Something Went Wrong!");
    }
  };

  return (
    loadingSuccess ? (<ProfileCreate color={'#283E9C'} url={`https://${CONFIG.PREFIX_NAME}-${url}.${CONFIG.DOMAIN_NAME}`}></ProfileCreate>) :
      (<div className="card client-url-container mt-3 p-2">

        <div className="row">
          <div className="col-12 ">
            <div className="url-heading">
              <h2>
                Almost done! create your  URL
              </h2>

            </div>
          </div>
        </div>
        <form onSubmit={(e) => register(e)}>
          <div className="row">
            <div className="col-md-12 col-lg-12 mt-3">
              <div className="url-input">
                <div className="input-container">
                  {/* <label className={isFocused || url ? 'floating-label' : ''} htmlFor="txt">
                    Create your business URL
                  </label> */}
                  <input
                    type="text"
                    value={url}
                    id="txt"
                    className="form-control user-url p-2 pl-3 mb-3 mt-2"
                    placeholder="Create your business URL"
                    name="url"
                    autoComplete="off"
                    onChange={(e) => handleUrlChange(e.target.value)}
                    onPaste={(e) => handleUrlChange(e.target.value)}
                  />
                </div>
                <div className="url-notes">
                  <small
                    style={{
                      color: "black",
                      fontWeight: '500'
                    }}
                  >
                    Please copy your  portal Link
                  </small>
                  <b className="candidate" >
                    <span style={{ fontSize: "18px", fontWeight: '500' }}>
                      Canndidate URL:&nbsp;
                    </span>
                    {url ? url : '{url}'}.blinkexam.com &nbsp;
                    <CopyToClipboard style={{ color: '#283E9C' }}
                      text={`${url}.${CONFIG.DOMAIN_NAME}`}
                      onCopy={() => setCopied(true)}
                    >
                      <MdOutlineCopyAll size={25} />
                    </CopyToClipboard>
                    {copied && (
                      <span style={{ fontSize: "10px", color: '#3BB900' }}>
                        Copied!
                      </span>
                    )}
                  </b>

                  <b >
                    <span style={{ fontSize: "18px", fontWeight: '500' }}>Admin URL: </span>
                    {CONFIG.PREFIX_NAME}-{url ? url : '{url}'}.{CONFIG.DOMAIN_NAME}&nbsp;
                    <CopyToClipboard style={{ color: '#283E9C' }}
                      text={`${CONFIG.PREFIX_NAME}-${url}.${CONFIG.DOMAIN_NAME}`}
                      onCopy={() => setManage(true)}
                    >
                      <MdOutlineCopyAll size={25} />
                    </CopyToClipboard>
                    {manage && (
                      <span style={{ fontSize: "10px", color: '#3BB900' }}>
                        &nbsp; Copied!
                      </span>
                    )}
                  </b>

                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12 col-lg-12 ">
              <div className="sign-btn">
                <button
                  type="submit"
                  className="btn Sign-up col-12  text-dark"
                  disabled={loading || !url}
                >
                  {loading ? <div  disabled>
                    Create Panel
              <span class="spinner-border spinner-border-sm " style={{marginLeft:'5%',color:'#fffff'}} role="status" aria-hidden="true"></span>
              </div> : "Create Panel"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>));
}
