import * as Yup from "yup";
import { BLOCKED_EMAILS } from '../blocked-emails';


export const signupValidationV1 = Yup.object({
  firstname: Yup.string().required("Please enter your firstname").matches(/^[aA-zZ\s]+$/, "Firstname should contain only letters"),
  lastname: Yup.string().required("Please enter your lastname").matches(/^[aA-zZ\s]+$/, "Firstname should contain only letters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid Email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid E-mail address"
    )
    .test("email-include-domain", "Please use valid emails", (value) => !(BLOCKED_EMAILS.some((blockedEmail) => value.includes(blockedEmail)))),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .required('Confirm password is required')
    .test("is-password-same", "The provided passwords do not match", function (cpass) {
      const { password } = this.parent;
      return cpass.match(password);
    }),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Invalid phone number"),
  industry: Yup.string().required("Please select industry type"),
});



export const signupValidationV2 = Yup.object({
  firstname: Yup.string().required("Please enter your firstname").matches(/^[aA-zZ\s]+$/, "Firstname should contain only letters"),
  lastname: Yup.string().required("Please enter your lastname").matches(/^[aA-zZ\s]+$/, "Firstname should contain only letters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid Email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid E-mail address"
    )
    .test("email-include-domain", "Please use valid emails", (value) => !(BLOCKED_EMAILS.some((blockedEmail) => value.includes(blockedEmail)))),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .required('Confirm password is required')
    .test("is-password-same", "The provided passwords do not match", function (cpass) {
      const { password } = this.parent;
      return cpass.match(password);
    }),
  industry: Yup.string().required("Please select industry type"),
});
