import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";
import Logo from "./assets/BlinkExam.svg";
import Computer from "./assets/computer_v2.svg";
import reportWebVitals from "./reportWebVitals";
import { CONFIG } from "./app-config";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (CONFIG.GA_ID) {
  ReactGA.initialize(CONFIG.GA_ID);
}

root.render(
  <React.StrictMode>
    <Router>
      <div className="container-fluid left-side-container p-0">
        <div className="row m-0 p-0">
          <div className="col-xl-6 col-md-6 p-0">
            <div className="static-data pt-4 ">
              <div className="text-container">
                <div className="container logo-container">
                  <img src={Logo} className="img-fluid" alt="BlinkExam Logo" />
                </div>
                <div className="container">
                  <div className="trial-info">
                    <p className="heading-text pt-3 mb-1">Start your free trial</p>
                    <p className="sub-heading">No credit card required, no software to install</p>
                    <ul>
                    <li className="paragraph mb-4">With your 7-day trial, you get:</li>
                    <li className="paragraph mb-4">6 Types Of Questions</li>
                    <li className="paragraph mb-4">2 Types of Tests: Normal test & Quiz</li>
                    <li className="paragraph mb-4">Get 20 free tests for a limited period of time.</li>
                    <li className="paragraph mb-4">Online training and live onboarding webinar</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="logo-container-computer ms-3">
                    <img src={Computer} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 col-xs-12 p-0">
            <App />
          </div>
        </div>
      </div>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
