import { CONFIG } from "../app-config";
import { StorageKeys } from "../constant";


export default class CaptchaService {

    async generateCaptchaToken() {
        return new Promise((resolve, reject) => {
            window.grecaptcha.ready(async () => {
                try {
                    const token = await window.grecaptcha.execute(
                        CONFIG.GOOGLE_CAPTCHA_SITE_KEY,
                        { action: "client_signup" }
                    );
                    localStorage.setItem(StorageKeys.CAPTCHA, token);
                    resolve(token);
                } catch (error) {
                    console.error(`Error executing reCAPTCHA: ${error}`);
                    reject(false);
                }
            });
        });
    }


}